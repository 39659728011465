import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Label } from 'reactstrap'



export default function Footer() {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [isDisclaimer, setIsDisclaimer] = useState(false);
  useEffect(() => {
    const updateScrollPercentage = () => {
      const scrollPx = document.documentElement.scrollTop;
      const winHeightPx =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (scrollPx / winHeightPx) * 100;

      setScrollPercentage(scrolled);
    };

    window.addEventListener('scroll', updateScrollPercentage);

    return () => {
      window.removeEventListener('scroll', updateScrollPercentage);
    };
  }, []);

  useEffect(() => {
    let time = null;
    const getData = async () => {
      const item = await localStorage.getItem('policy');
      console.log('policy from storage', item);
      if (!item)
        time = setTimeout(() => {
          setIsDisclaimer(true)
        }, 3000);
    }
    getData();
    return () => clearTimeout(time);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 600) {
        setIsDisclaimer(true);
      } else {
        setIsDisclaimer(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleOkayDisclaimer = () => {
    setIsDisclaimer(false);
    localStorage.setItem('policy', 'agreed');
  }

  return (
    <footer className='footer top padding-left-right'>

      <div className="container-fluid p-0">
        <div className='row'>
          <div className="footer-col col-lg-3 col-md-6">
            <div className='footer-logo'>
              <img src={require("../assets/images/logo-ambika.png")} alt="amibika-maxx" />
            </div>
            {/* <h4>BrandStreet</h4> */}
            <p>Saran Chamber1, Park Road, Hazratganj Lucknow Uttar Pradesh</p>
            <ul className='mb-4'>
              <li><a href="tel:18008897990"><i class="fa-solid fa-phone"></i>&nbsp;18008897990 (Toll Free)</a></li>
            </ul>
            {/* <ul>
              <li><a href="mailto:info@ambikamaxxpaint.in"><i class="fa-solid fa-envelope"></i> &nbsp;info@ambikamaxxpaint.in</a></li>
              <li><a href="mwww.ambikamaxxpaint.in">www.ambikamaxxpaint.in</a></li>
            </ul> */}
          </div>
          <div className="footer-col col-lg-3 col-md-6">
            <h4>Quicklink</h4>
            <ul>
              <li><a href="/">Home</a></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/gallery">Gallery</Link></li>
              <li><Link to="/distributorship">Become A distribute</Link></li>
              <li><Link to="/career">Career</Link></li>
              <li><Link to="/contact-us">Contact Us</Link></li>
            </ul>
          </div>
          <div className="footer-col col-lg-3 col-md-6">
            <h4>Our Products</h4>
            <ul>
              <li><Link to="/wall-putty">Wall Putty</Link></li>
              <li><Link to="/decorative-white-cement">Decorative White Cement</Link></li>
              <li><Link to="/grout">Grout</Link></li>
              <li><Link to="/tiles-adhesive">Tiles Adhesive</Link></li>
              <li><Link to="/gypsum-plaster-pop">Gypsum Plaster (POP)</Link></li>
              <li><Link to="/water-based-paint">All Types of Water Based Paint</Link></li>
            </ul>
          </div>
          <div className="footer-col col-lg-3 col-md-6">

            <div class="col-sm-12">
              {/* <Label>Your Email*</Label> */}
              <form className='footer-form'>
                <input type="email" placeholder="Email" className="inptEmail" required />
                <button className='btn footer-btn btn-class'>Subscribe<span>Subscribe</span></button>
                <p className='mt-0 pt-0'></p>
              </form>
            </div>

            <div className="social-links">
              <li>
                <a href="#" target='_blank'><i className="fa-brands fa-facebook-f"></i></a>
              </li>
              <li>
                <a href="#" target='_blank'><i className="fa-brands fa-instagram"></i></a>
              </li>
              <li>
                <a href="#" target='_blank'><i className="fa-brands fa-twitter"></i></a>
              </li>
              <li>
                <a href="#" target='_blank'><i className="fa-brands fa-youtube"></i></a>
              </li>
            </div>
          </div>
        </div>


      </div>
      <div className='container-fluid'>
        <div className='footer-copyright row'>
          <div className='col-lg-6 p-0'>
            <div className='copy-right-p'>
              <p> &copy;&nbsp;2024 Ambika Maxx </p>
            </div>
          </div>
          <div className='col-lg-6 p-0'>
            <div className='privacy-policy'>
              <p><Link to='/privacy-policy'>Privacy Policy</Link></p>
            </div>
          </div>
          {/* <div className='col-lg-4 p-0'>
            <div className='website-link'>
              <p>Website by <a href='https://www.netseems.com/' target='_blank'>Netseems</a></p>
            </div>
          </div> */}
        </div>
      </div>

      {/* <div class="progress-circle-container">

        <svg viewBox="0 0 36 36" className="circular-chart">
          <path
            className="circle-bg"
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="circle"
            strokeDasharray={`${scrollPercentage}, 100`}
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>

        <div className="uparrow">
          <a href="#"><i className="fa fa-arrow-up"></i></a>
        </div>

      </div> */}
    </footer>
  )
}


