import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import pdfPath11 from '../../assets/images/product/pdf/BrownModernInteriorDesignPoster(11).pdf';
import pdfPath7 from '../../assets/images/product/pdf/BrownModernInteriorDesignPoster7.pdf';
import pdfPath8 from '../../assets/images/product/pdf/Brown Modern Interior Design Poster (8).pdf';
import pdfPath9 from '../../assets/images/product/pdf/Brown Modern Interior Design Poster (9).pdf';
import pdfPath10 from '../../assets/images/product/pdf/Brown Modern Interior Design Poster (10).pdf';
import pdfIcons from '../../assets/images/pdf.png';

export default function ServiceInclude() {

    const location = useLocation();
    const [activeLink, setActiveLink] = useState('');

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location]);

    const isActive = (path) => {
        return path === activeLink;
    };

    return (
        <div className='right-section-service'>
            <h3 className='sub-heading'><b>Our Products</b> </h3>
            <ul>
                <li style={{
                    background: isActive('/wall-putty') ? '#e31e23' : '#0f2a83'
                }}>
                    <i aria-hidden="true" className="fas fa-circle"></i> <Link to={"/wall-putty"}>Wall Putty</Link>
                </li>
                <li style={{ background: isActive('/decorative-white-cement') ? '#e31e23' : '#0f2a83' }}>
                    <i aria-hidden="true" className="fas fa-circle"></i> <Link to={"/decorative-white-cement"}>Decorative White Cement</Link>
                </li>
                <li style={{ background: isActive('/grout') ? '#e31e23' : '#0f2a83' }}>
                    <i aria-hidden="true" className="fas fa-circle"></i> <Link to={"/grout"}>Grout</Link>
                </li>
                <li style={{ background: isActive('/tiles-adhesive') ? '#e31e23' : '#0f2a83' }}>
                    <i aria-hidden="true" className="fas fa-circle"></i> <Link to={"/tiles-adhesive"}>Tiles Adhesive</Link>
                </li>
                <li style={{ background: isActive('/gypsum-plaster-pop') ? '#e31e23' : '#0f2a83' }}>
                    <i aria-hidden="true" className="fas fa-circle"></i> <Link to={"/gypsum-plaster-pop"}>Gypsum Plaster (POP)</Link>
                </li>
                <li style={{ background: isActive('/creative-services') ? '#e31e23' : '#0f2a83' }}>
                    <i aria-hidden="true" className="fas fa-circle"></i> <Link to={"/creative-services"}>All Types of Water Based Paint</Link>
                </li>

            </ul>

            <ul style={{ marginTop: '30px' }}>
                <li style={{ background: '#0f2a83' }}>
                    <i aria-hidden="true" className="fas fa-circle"></i> Brochure
                </li>
                <li style={{ background: '#0f2a83' }}>
                    <a href={pdfPath11} download="Brown_Modern_Interior_Design_Poster.pdf" style={{ color: 'white', textDecoration: 'none' }}>
                        <img
                            src={pdfIcons}
                            alt="PDF icon"
                            style={{ width: '45px', height: 'auto' }}
                        />&nbsp;
                        Grout
                    </a>
                </li>
                <li style={{ background: '#0f2a83' }}>
                    <a href={pdfPath7} download="Brown_Modern_Interior_Design_Poster.pdf" style={{ color: 'white', textDecoration: 'none' }}>
                        <img
                            src={pdfIcons}
                            alt="PDF icon"
                            style={{ width: '45px', height: 'auto' }}
                        />&nbsp;
                        Wall Putty
                    </a>
                </li>
                <li style={{ background: '#0f2a83' }}>
                    <a href={pdfPath8} download="Brown_Modern_Interior_Design_Poster.pdf" style={{ color: 'white', textDecoration: 'none' }}>
                        <img
                            src={pdfIcons}
                            alt="PDF icon"
                            style={{ width: '45px', height: 'auto' }}
                        />&nbsp;
                        Tiles Adhesive
                    </a>
                </li>
                <li style={{ background: '#0f2a83' }}>
                    <a href={pdfPath9} download="Brown_Modern_Interior_Design_Poster.pdf" style={{ color: 'white', textDecoration: 'none' }}>
                        <img
                            src={pdfIcons}
                            alt="PDF icon"
                            style={{ width: '45px', height: 'auto' }}
                        />&nbsp;
                        Decorative White Cement
                    </a>
                </li>
                <li style={{ background: '#0f2a83' }}>
                    <a href={pdfPath10} download="Brown_Modern_Interior_Design_Poster.pdf" style={{ color: 'white', textDecoration: 'none' }}>
                        <img
                            src={pdfIcons}
                            alt="PDF icon"
                            style={{ width: '45px', height: 'auto' }}
                        />&nbsp;
                        Gypsum Plaster (POP)
                    </a>
                </li>
            </ul>
        </div>
    )
}
