import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion, useTime } from "framer-motion"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Autoplay, Navigation, Keyboard } from 'swiper/modules';
import { get } from '../pages/helper/helper_api';
import CountUp from 'react-countup';
import { Parallax, useParallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import Inquiry from '../component/Inquiry';
import { toast } from "react-toastify";
import FeaturedProject from '../component/FeaturedProject';
import CountPage from "../component/CountPage";
import videoSrc from '../assets/images/video/ai-ml-new.mp4';

export default function Home() {
    const swiperRef1 = useRef();
    const [location, setLocation] = useState([]);
    const [error, setError] = useState(false);

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    }, []);

    const getData = () => {
        get("/client/list")
            .then(json => {
                console.log('response from get project list', json);
                if (json?.statusCode == 200) {
                    setLocation(json?.data);
                } else {
                    setError(true);
                }
            })
            .catch(error => {
                console.log('error while getting project list', error);
            })
    }

    return (
        <>
            <div className='banner-slider'>
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    navigation={false}
                    modules={[Autoplay]}
                    className=" home-slider"
                >
                    <SwiperSlide>

                        <div className='banner-section '>

                            <img src={require('../assets/images/banner.jpg')} alt='banner' className='web-banner' />
                            <div className='banner-content padding-left-right'>

                                {/* <div className='dot-img'>
                                    <img src={require('../assets/images/white-dot.png')} alt='white-dot' />
                                </div> */}
                                <h6 className='sub-heading' style={{ color: '#FFF' }}>AMBIKA MAXX </h6>
                                <h1 className='heading'>We Brand Quality.<br /> Quality Leads With The Industry!</h1>
                                <p className='paragraph'>Eight years involved with the nuclear industry have taught me that when nothing can possible go wrong and every avenue has been covered</p>
                                <a href='#' className='btn-class'>
                                    Learn More
                                    <span>Learn More</span>
                                </a>
                            </div>
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='banner-section '>

                            <img src={require('../assets/images/banner-2.jpg')} className='web-banner' alt='banner-2' />
                            <div className='banner-content padding-left-right'>
                                {/* <div className='dot-img'>
                                    <img src={require('../assets/images/white-dot.png')} alt='white-dot' />
                                </div> */}
                                <h6 className='sub-heading' style={{ color: '#FFF' }}>AMBIKA MAXX </h6>
                                <h1 className='heading'>A Brighter Tomorrow<br />
                                    Starts Today.<br />
                                    Into Advocates</h1>
                                <p className='paragraph'>The number, the industry, and the morality of the priesthood, and the devotion of the people have been manifestly increased by the total separation of the church from the state.</p>
                                <a href='#' className='btn-class'>
                                    Learn More
                                    <span>Learn More</span>
                                </a>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>

            <section className='top padding-left-right marketing-section'>
                <div className='container-fluid px-0'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='left-section'>
                                <h6

                                    className='sub-heading wow fadeInUp'>Ambika Maxx India Pvt Ltd</h6>
                                <h2 className='heading wow fadeInUp'>Pioneering Superior Building Solutions for a Robust Future</h2>
                                <p className='paragraph wow fadeInUp'>At Ambika Maxx India Pvt Ltd, we pride ourselves on being at the forefront of the building materials industry in Rajasthan. With decades of experience, our commitment to quality and durability stands paramount. Our extensive product range, including wall putty, white cement, and specialty adhesives, is crafted using state-of-the-art technology and the finest raw materials, ensuring top performance and impeccable finishes in every project.

                                </p>
                                {/* <button className='video-play-btn'>
                                    <i class="fa-sharp fa-solid fa-play"></i>
                                    <span>Watch Video</span>
                                </button> */}
                                <div className='explain-more wow fadeInUp'>
                                    <Link to='/about' className='btn-class' style={{ background: '#c9191e' }}>
                                        More About
                                        <span>More About</span>
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 right_s' style={{ overflow: 'hidden' }}>
                            <Parallax
                                speed={20}

                            >
                                <div className='right-section'>

                                    <img className='wow slideInRight' src={require('../assets/images/team.jpg')} alt='team' />

                                </div>
                            </Parallax>
                        </div>
                    </div>
                </div>
            </section>

            <section className='padding-left-right marketing-section'>
                <div className="information-video-slide">
                    <div className="information-item">
                        <video className="information-video" autoPlay muted loop>
                            <source src={videoSrc} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </section>

            <section className='bottom padding-left-right featured-section portfolio_section'>
                <div className='header-title mb-40px'>
                    <div>
                        {/* <h6 className='sub-heading wow fadeInUp'>NEWS AND UPDATES</h6> */}
                        <h2 className='heading wow fadeInUp'>Our Products</h2>
                        <p>Explore Our Comprehensive Range of Building Materials Designed for Excellence and Durability
                        </p>
                    </div>
                </div>

                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-6'>
                            <div className='service_box'>
                                <div className='thumb-img'>
                                    <Link to="/wall-putty"
                                        className='news_link-box'>
                                        <img src={require('../assets/images/project/1b625a8c245ab3805719da9f12e6486e.jpg')} alt='' style={{ height: '300px', width: '245px' }} />
                                    </Link>
                                </div>
                                <div className='service-detail-box'>
                                    <div className='service-flex'>
                                        <div className='left-psd'>
                                            <h4>Wall Putty</h4>
                                            <p className=''>The industry is made up of companies and workers who manufacture and deliver cars, trucks, and other vehicles to companies that sell them. Companies...</p>
                                        </div>

                                        <div className='right-psd'>
                                            {/* <p><img src={require('../assets/images/banner/features/whatsapp_733585.png')} /></p> */}
                                        </div>
                                    </div>
                                    <Link to="/wall-putty">Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-6'>
                            <div className='service_box'>
                                <div className='thumb-img'>
                                    <Link to={"/decorative-white-cement"}
                                        className='news_link-box'>
                                        <img src={require('../assets/images/white-cement.webp')} alt='' style={{ height: '300px', width: '300px' }} />
                                    </Link>
                                </div>
                                <div className='service-detail-box'>
                                    <div className='service-flex'>
                                        <div className='left-psd'>
                                            <h4>Decorative White Cement</h4>
                                            <p className=''>The electric power industry covers the generation, transmission and sale of electric. The overall effect was that Edison's system required power. Term automation, inspired...</p>
                                        </div>
                                        <div className='right-psd'>
                                            {/* <p><img src={require('../assets/images/banner/features/whatsapp_733585.png')} /></p> */}

                                        </div>
                                    </div>
                                    {/* <p className=''>The campaign was executed by engaging consumers in fitness challenges & tests of endurance...</p> */}
                                    <Link to={"/decorative-white-cement"}>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-6'>
                            <div className='service_box'>
                                <div className='thumb-img'>
                                    <Link to={"/grout"}
                                        className='news_link-box'>
                                        <img src={require('../assets/images/Grout.webp')} alt='' style={{ height: '300px', width: '300px' }} />
                                    </Link>
                                </div>
                                <div className='service-detail-box'>
                                    <div className='service-flex'>
                                        <div className='left-psd'>
                                            <h4>Grout</h4>
                                            <p className=''>Industrial construction is a specific form of building that requires expert training and highly experienced. Term automation, inspired by the earlier word, lorem ipsum...</p>
                                        </div>
                                        <div className='right-psd'>
                                            {/* <p><img src={require('../assets/images/banner/features/whatsapp_733585.png')} /></p> */}

                                        </div>
                                    </div>
                                    {/* <p className=''>The campaign was executed by engaging consumers in fitness challenges & tests of endurance...</p> */}
                                    <Link to={"/grout"}>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-6'>
                            <div className='service_box'>
                                <div className='thumb-img'>
                                    <Link to={"/tiles-adhesive"}
                                        className='news_link-box'>
                                        <img src={require('../assets/images/TielsAdhesive.webp')} alt='' style={{ height: '290px', width: '285px' }} />
                                    </Link>
                                </div>
                                <div className='service-detail-box'>
                                    <div className='service-flex'>
                                        <div className='left-psd'>
                                            <h4>Tiles Adhesive</h4>
                                            <p className=''>Petroleum refining processes are the chemical engineering processes and other facilities used in petroleum refineries (also referred to as oil refineries) to transform crude...</p>
                                        </div>
                                        <div className='right-psd'>
                                            {/* <p><img src={require('../assets/images/banner/features/whatsapp_733585.png')} /></p> */}

                                        </div>
                                    </div>
                                    {/* <p className=''>The campaign was executed by engaging consumers in fitness challenges & tests of endurance...</p> */}
                                    <Link to={"/tiles-adhesive"}>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-6'>
                            <div className='service_box'>
                                <div className='thumb-img'>
                                    <Link to={"/gypsum-plaster-pop"}
                                        className='news_link-box'>
                                        <img src={require('../assets/images/plaster.jpeg')} alt='' style={{ height: '294px', width: '195px', }} />
                                    </Link>
                                </div>
                                <div className='service-detail-box'>
                                    <div className='service-flex'>
                                        <div className='left-psd'>
                                            <h4>Gypsum Plaster (POP)</h4>
                                            <p className=''>Automation is the topmost technology in today’s world and we are the topper at it. We have already provided many industries with the perfect...</p>
                                        </div>
                                        <div className='right-psd'>
                                            {/* <p><img src={require('../assets/images/banner/features/whatsapp_733585.png')} /></p> */}

                                        </div>
                                    </div>
                                    {/* <p className=''>The campaign was executed by engaging consumers in fitness challenges & tests of endurance...</p> */}
                                    <Link to={"/gypsum-plaster-pop"}>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-6'>
                            <div className='service_box'>
                                <div className='thumb-img'>
                                    <Link to={"/"}
                                        className='news_link-box'>
                                        <img src={require('../assets/images/project/1b625a8c245ab3805719da9f12e6486e.jpg')} alt='' style={{ height: '300px', width: '245px' }} />
                                    </Link>
                                </div>
                                <div className='service-detail-box'>
                                    <div className='service-flex'>
                                        <div className='left-psd'>
                                            <h4>All Types of Water Based Paint</h4>
                                            <p className=''>Ensure a smooth and flawless finish for your walls with our high-quality wall putty, available in 5 kg, 20 kg, and 40 kg packages...</p>
                                        </div>
                                        <div className='right-psd'>
                                            {/* <p><img src={require('../assets/images/banner/features/whatsapp_733585.png')} /></p> */}

                                        </div>
                                    </div>
                                    {/* <p className=''>The campaign was executed by engaging consumers in fitness challenges & tests of endurance...</p> */}
                                    <Link to={"/"}>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-lg-4'>
                            <RightSection />
                        </div> */}
                    </div>
                </div>
            </section>

            <section className='bottom padding-left-right featured-section'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='award-img-left'>
                                <img src={require('../assets/images/why-choose.jpg')} height='40%' width='100%' alt="service" />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='award-right-text'>
                                <h6>Why choose us</h6>
                                <h2 className='heading wow fadeInUp'>Quality, Innovation, and Sustainability in Every Product</h2>
                                <p className='paragraph1 wow fadeInUp'>At Ambika Maxx India, we are dedicated to providing top-tier building materials that ensure the utmost quality and durability. Our commitment to innovation and environmental sustainability makes us a trusted partner in your construction endeavors.</p>

                                <ul>
                                    <li><i class="fa-solid fa-check"></i> Superior Quality Materials: Each product is crafted using the finest raw materials and cutting-edge technology for optimal performance.</li>
                                    <li><i class="fa-solid fa-check"></i> Sustainable Practices: We prioritize eco-friendly processes to minimize our environmental impact while maintaining product excellence.</li>
                                    <li><i class="fa-solid fa-check"></i> Customer-Centric Approach: From comprehensive customer support to tailored solutions, your satisfaction is at the core of our operations.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='bottom padding-left-right' style={{ backgroundColor: 'white' }}>

                <div className='header-title text-center'>
                    <h6 className='sub-heading wow fadeInUp'>WHAT OUR CLIENTS ARE SAYING</h6>
                    <center><div className="blue-line"></div></center><br />
                </div>
                <div className='row'>
                    <div className='col-md-6 col-lg-3'>
                        <div class="satisfaction-box text-center">
                            <div class="icon">
                                <i class="fas fa-handshake"></i>
                            </div>
                            <h2>10,000+</h2>
                            <p>CUSTOMER SATISFACTION</p>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        <Swiper
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            modules={[Pagination, Autoplay]}
                            className="testimonialSwiper"
                        >
                            <SwiperSlide>
                                <div className='slider_container'>
                                    <div className='slider_3'>
                                        <div className='slider-text'>
                                        </div>
                                        <div className='slider-para wow fadeInUp'>
                                            "Ambika Maxx India has consistently delivered high-quality materials that our projects depend on. Their wall putty and cement are some of the best we've used, and their service is always outstanding."
                                        </div>
                                        <center><div className="blue-line"></div></center>
                                        <h6 className='wow fadeInUp'>RAJESH KUMAR</h6>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='slider_container'>
                                    <div className='slider_3'>
                                        <div className='slider-text'>
                                        </div>
                                        <div className='slider-para wow fadeInUp'>
                                            "There are many things that can set Brand street  apart  – cost effectiveness, innovativeness of ideas, executional excellence to name a few- but I would recommend them foremost for superior client service. They are very prompt, efficient and always mee."
                                        </div>
                                        <center><div className="blue-line"></div></center>
                                        <h6 className='wow fadeInUp'>RICHA OJHA</h6>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='slider_container'>
                                    <div className='slider_3'>
                                        <div className='slider-text'>
                                        </div>
                                        <div className='slider-para wow fadeInUp'>
                                            "Working with Brand Street Integrated has been a game-changer for our brand. Their expertise in experiential marketing truly sets them apart. Our consumer community has flourished under their guidance, and the impact on our brand's differentiation is undeniable."
                                        </div>
                                        <center><div className="blue-line"></div></center>
                                        <h6 className='wow fadeInUp'>AKASH BHARDWAJ</h6>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='slider_container'>
                                    <div className='slider_3'>
                                        <div className='slider-text'>
                                        </div>
                                        <div className='slider-para wow fadeInUp'>
                                            "Brand Street Integrated understands the importance of creating memorable experiences for our target audience. Their dedication to building lasting connections through experiential marketing has been instrumental in our brand's success."
                                        </div>
                                        <center><div className="blue-line"></div></center>
                                        <h6 className='wow fadeInUp'>VIKASH SHARMA</h6>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>

            <CountPage />

            <FeaturedProject />

            <Inquiry />

            <section className='achievement-section top bottom'>
                <div className='header-title mb-40px'>
                    <h3 className='heading wow fadeInUp' >Achievements</h3>
                    <p className='paragraph'>Empowering individuals to reach their full potential through relentless pursuit of excellence and unwavering dedication to success.</p>
                </div>
                <div className='image-slider'>

                    <Swiper
                        slidesPerView={2.6}
                        spaceBetween={0}
                        navigation={false}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 4.7,
                                spaceBetween: 0,
                            },
                            768: {
                                slidesPerView: 5,
                                spaceBetween: 0,
                            },
                            1024: {
                                slidesPerView: 8,
                                spaceBetween: 0,
                            },
                        }}

                        modules={[Autoplay]}
                        className="iconSwiper"
                    >
                        <SwiperSlide>
                            <div className='award_item'>
                                <img src={require('../assets/images/icon/award-1.jpg')} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='award_item'>
                                <img src={require('../assets/images/icon/award-2.jpg')} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='award_item'>
                                <img src={require('../assets/images/icon/award-3.jpg')} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='award_item'>
                                <img src={require('../assets/images/icon/award-4.jpg')} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='award_item'>
                                <img src={require('../assets/images/icon/award-1.jpg')} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='award_item'>
                                <img src={require('../assets/images/icon/award-2.jpg')} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='award_item'>
                                <img src={require('../assets/images/icon/award-3.jpg')} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='award_item'>
                                <img src={require('../assets/images/icon/award-4.jpg')} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='award_item'>
                                <img src={require('../assets/images/icon/award-1.jpg')} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='award_item'>
                                <img src={require('../assets/images/icon/award-2.jpg')} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='award_item'>
                                <img src={require('../assets/images/icon/award-3.jpg')} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='award_item'>
                                <img src={require('../assets/images/icon/award-4.jpg')} alt="" />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>

        </>
    )
}
