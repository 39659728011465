import React, { useEffect, useRef } from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useParallax } from 'react-scroll-parallax';
import ServiceInclude from './ServiceInclude';
import { Link } from 'react-router-dom';
import Whychooseus from '../../component/Whychooseus';
export default function Merchandising() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const style = {
        color: 'white'
    }

    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading' style={style}>Tiles Adhesiv</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true" style={style}></i></li>
                        <li className="breadcrumb-item active" aria-current="page" style={style}>Tiles Adhesive</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='service-detail-left-side'>
                                <h3 className='sub-heading'> OUR PRODUCT</h3>
                                <h2 className='heading'>Tiles Adhesive</h2>

                                <p>Our Tile Adhesive is formulated to provide a robust bond for all types of tiles, ensuring they stay firmly in place. Whether you’re working on residential bathrooms or commercial kitchens, our adhesive comes in 5 kg and 20 kg options to cater to both small and large scale projects. It’s perfect for ceramic, porcelain, and stone tiles, offering a reliable solution that contractors and DIY enthusiasts trust.</p>
                                <p>This adhesive is not only strong but also easy to use, providing excellent workability and a long adjustment time so you can achieve the perfect finish. It is designed to resist moisture and heat, making it ideal for areas like bathrooms and kitchens where durability is crucial. With our Tile Adhesive, you can ensure a smooth, problem-free application that results in beautifully laid tiles that last.</p>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/merchandising/tiles-5kg.webp')} height="200px" />
                                            <h4> 5 Kg</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/merchandising/tiles-20kg.webp')} height="200px" />
                                            <h4> 20 Kg</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <ServiceInclude />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
