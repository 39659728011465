import React from 'react'

export default function Whychooseus() {
    return (
        <section className=' padding-left-right business'>

            <div className='business-body'>
                <h6>Why choose us</h6>
                <h2 className='heading wow fadeInUp'>We Grow your Business</h2>
                <p className='paragraph1 wow fadeInUp'>At Brand Street Integrated, our mission is simple yet powerful: we grow your business. With a comprehensive suite of services spanning consumer marketing, trade marketing, digital solutions, creative services, and more, we are dedicated to propelling your brand to new heights.</p>

                <div className='card-box row'>
                    <div className='col-lg-3 col-md-6 wow fadeInUp' data-wow-delay="0.2s">
                        <div className="cards">
                            <div className='line_border'></div>
                            <div className='box-text'>
                                <h4>Planning</h4>
                                <p>Strategize for success with meticulous foresight.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 wow fadeInUp' data-wow-delay="0.4s">
                        <div className="cards">
                            <div className='line_border'></div>
                            <div className='box-text'>
                                <h4>Processing</h4>
                                <p>Streamline operations for seamless efficiency and productivity.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 wow fadeInUp' data-wow-delay="0.6s">
                        <div className="cards">
                            <div className='line_border'></div>
                            <div className='box-text'>
                                <h4>Engaging</h4>
                                <p>Captivate audiences with immersive experiences and interactions.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 wow fadeInUp' data-wow-delay="0.9s">
                        <div className="cards">
                            <div className='line_border'></div>
                            <div className='box-text'>
                                <h4>Result</h4>
                                <p>Achieve tangible outcomes through strategic execution.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}
