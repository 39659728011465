import React, { useEffect, useRef, useState } from 'react'
import { Parallax, useParallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import CountPage from '../component/CountPage';
import { get } from './helper/helper_api';
export default function About() {

    const swiperRef1 = useRef();
    const [team, setTeam] = useState([]);
    const [error, setError] = useState(false);

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    }, []);

    const style = {
        color: 'white',
    }

    const getData = () => {
        get("/team/list")
            .then(json => {
                console.log('response from get project list', json);
                if (json?.statusCode == 200) {
                    setTeam(json?.data);
                } else {
                    setError(true);
                }
            })
            .catch(error => {
                console.log('error while getting project list', error);
            })
    }

    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading' style={style}>Team</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true" style={style}></i></li>
                        <li className="breadcrumb-item active" aria-current="page" style={style}>Team</li>
                    </ol>
                </nav>
            </section>

            <section className='about-section-4 top bottom padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'> OUR TEAMS</h3>
                        <p>Meet the Minds Behind Our Innovation</p><br />
                        <center><div className='blue-line'></div></center>

                    </div>
                    <div className='row'>
                        {team?.map((item, index) => (
                            <div className='col-lg-3 col-md-4 col-sm-6'>
                                <div className='team-profile'>
                                    <div className='profile-box'>
                                        <img src={item?.image} />
                                        <div className='team-social-media'>
                                        </div>
                                    </div>
                                    <h4>{item?.title}</h4>
                                    <h5>{item?.desig}</h5>
                                </div>
                            </div>
                        ))}
                        <div className='col-lg-3 col-md-4 col-sm-6'>
                            <div className='team-profile'>
                                <div className='profile-box'>
                                    <img src={require('../assets/images/team/1.jpg')} />
                                    <div className='team-social-media'>
                                    </div>
                                </div>
                                <h4>ARUN KUMAR VISHWAKARMA</h4>
                                <h5>CEO & Founder</h5>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6'>
                            <div className='team-profile'>
                                <div className='profile-box'>
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLnX7BzP-A20QL7BGfFOBwpy7fwWW35d6pkw&s" alt="" />
                                    <div className='team-social-media'>
                                    </div>
                                </div>
                                <h4>NEETOO SHARMA</h4>
                                <h5>Co. Founder</h5>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6'>
                            <div className='team-profile'>
                                <div className='profile-box'>
                                    <img src={require('../assets/images/team/4.jpg')} alt='' />
                                    <div className='team-social-media'>
                                        {/* <li>
                                            <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa-brands fa-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </li> */}
                                    </div>
                                </div>
                                <h4>CHHOTE LAL VISHWAKARMA</h4>
                                <h5>Director</h5>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6'>
                            <div className='team-profile'>
                                <div className='profile-box'>
                                    <img src={require('../assets/images/team/4.jpg')} />
                                    <div className='team-social-media'>
                                        {/* <li>
                                            <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa-brands fa-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </li> */}
                                    </div>
                                </div>
                                <h4>PRADEEP KUMAR</h4>
                                <h5> Director</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}
