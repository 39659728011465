import React, { useEffect, useRef } from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useParallax } from 'react-scroll-parallax';
import ServiceInclude from './ServiceInclude';
import { Link } from 'react-router-dom';
import Whychooseus from '../../component/Whychooseus';
import Information from '../../component/Information';
export default function ConsumerMarketing() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const style = {
        color: 'white'
    }
    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading' style={style}>Wall Putty</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item" ><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true" style={style}></i></li>
                        <li className="breadcrumb-item active" aria-current="page" style={style}>Wall Putty</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='service-detail-left-side'>
                                <h3 className='sub-heading'><i class="fa-solid fa-star-of-life"></i> OUR Product</h3>
                                <h2 className='heading'>Wall Putty</h2>
                                <p className=''>
                                    <strong>Tailored strategies to engage and captivate target consumer segments.</strong>
                                </p>
                                <p><b>Versatility and Performance: </b>Our premium wall putty is designed to deliver a smooth and even surface, essential for flawless paint application. Available in 5 kg, 20 kg, and 40 kg packs, it suits a variety of project sizes from minor touch-ups to large-scale renovations. Its superior adhesion and crack-resistant formula make it an ideal choice for both interior and exterior wall preparations, ensuring long-lasting results.</p>
                                <p><b>Easy Application and Durability: </b> This putty is formulated for ease of use, allowing both professionals and DIY enthusiasts to achieve professional-grade results. It dries to a tough, durable finish that resists moisture, helping to prevent paint peeling and flaking. Perfect for smoothing out imperfections and leveling surfaces, our wall putty ensures that your walls look pristine and well-maintained for years to come.</p>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/Consumer-Marketing/10kg.jpg')} height="200px" />
                                            <h4>5 Kg</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/Consumer-Marketing/10kg.jpg')} height="200px" />
                                            <h4>20 Kg</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/Consumer-Marketing/10kg.jpg')} height="200px" />
                                            <h4>40 Kg</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <ServiceInclude />
                        </div>
                    </div>
                </div>
            </section>
            <Information />
        </>
    )
}
