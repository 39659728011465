import React from 'react';
import CountUp from 'react-countup';


export default function Whychooseus() {
    return (
        <section className=' padding-left-right business'>
            <div className='business-body'>
                <div className='card-box row'>
                    <div className='col-lg-3 col-md-6 wow fadeInUp' data-wow-delay="0.2s">
                        <div className="cards">
                            <div className='line_border'></div>
                            <div className='box-text'>
                                <h2><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={10000} /> +</h2>
                                <p><b>CUSTOMERS SERVED</b></p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 wow fadeInUp' data-wow-delay="0.4s">
                        <div className="cards">
                            <div className='line_border'></div>
                            <div className='box-text'>
                                <h2><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={500} /> +</h2>
                                <p><b>SUCCEFULL PROJECTS</b></p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 wow fadeInUp' data-wow-delay="0.6s">
                        <div className="cards">
                            <div className='line_border'></div>
                            <div className='box-text'>
                                <h2><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={8} /> +</h2>
                                <p><b>PRODUCTS OFFERED</b></p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 wow fadeInUp' data-wow-delay="0.9s">
                        <div className="cards">
                            <div className='line_border'></div>
                            <div className='box-text'>
                                <h2><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={46} /> +</h2>
                                <p><b>FACTORY INDUSTRIAL</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}
