import React, { useState, useRef } from 'react'
import { Label } from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { post } from '../pages/helper/helper_api';
import { toast } from "react-toastify";


export default function Inquiry() {

    const [formData, setFormData] = useState({});
    const form = useRef();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleValidSubmit = (e, v) => {
        // setLoading(true);
        const { fname, lname, ...rest } = v;
        const name = `${fname || ''} ${lname || ''}`;
        const body = {
            ...rest,
            name: name.trim()
        };
        console.log("check body", body);
        // return;
        post('/contact', body)
            .then((res => {
                // setLoading(true);
                if (res?.statusCode == 200) {
                    // setLoading(false);
                    toast.success(res?.message);
                    form.current.reset();
                } else {
                    toast.error(res?.error);
                }
            }))
            .catch(error => {
                // setLoading(false);
                console.log('error while login', error);
                toast.error('Something went wrong');
            })
    }
    return (
        <>
            {/* Contact Us */}
            <section className='bottom padding-left-right  fontact-us-home'>
                <div className="contact-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="contact-detail">
                                    <h3 className="sub-heading wow fadeInUp">Contact us</h3>
                                    <h2 className='wow fadeInUp heading'>Get in Touch</h2>
                                    <div className='call-to-action'>
                                        <a href='tel:915757244789' className='wow fadeInUp'><i class="fa-solid fa-phone"></i>18008897990</a>

                                        <a href="mailto:hi@thebrandstreet.co" className='wow fadeInUp'><i class="fa-solid fa-envelope"></i> info@ambikamaxxpaint.in</a>
                                    </div>
                                    <div className='social_media'>
                                        <p>Follow us:</p>
                                        <ul>
                                            <li className='' data-wow-delay="0.1s">
                                                <a href="#" target='_blank' className="fb"><i className="fa-brands fa-facebook"></i></a>
                                            </li>
                                            <li className='' data-wow-delay="0.2s">
                                                <a href="#" target='_blank' className="insta"><i className="fa-brands fa-instagram"></i></a>
                                            </li>
                                            <li className='' data-wow-delay="0.4s">
                                                <a href="#" target='_blank' className="twitter"><i className="fa-brands fa-x-twitter"></i></a>
                                            </li>
                                            <li className='' data-wow-delay="0.5s">
                                                <a href="#" target='_blank' className="twitter"><i className="fa-brands fa-youtube"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='footer-fomr-section'>
                                    <div className='contact-form-footer contact-form'>
                                        <AvForm ref={form} onValidSubmit={handleValidSubmit}>
                                            <Label className='wow fadeInUp' data-wow-delay="0.1s">Your Name*</Label>
                                            <div className="row">
                                                <div className="col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                                                    <AvField
                                                        name="fname"
                                                        placeholder="First name..."
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{
                                                            required: { value: true, errorMessage: "This field is required!" },
                                                        }}
                                                    />
                                                </div>

                                                <div className="col-sm-6 wow fadeInUp" data-wow-delay="0.2s">
                                                    <AvField
                                                        name="lname"
                                                        placeholder="Last name..."
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{
                                                            required: { value: true, errorMessage: "This field is required!" },
                                                        }}
                                                    />
                                                </div>
                                                <input type="hidden" name="name" value={`${formData.fname || ''} ${formData.lname || ''}`} />
                                            </div>

                                            <div classNameName='row'>
                                                <Label className='wow fadeInUp' data-wow-delay="0.4s">Your Email*</Label>
                                                <div className="col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                                                    <AvField name="email" placeholder="example@gmail.com..." className="form-control" validate={{
                                                        required: { value: true, errorMessage: "This field is required!" },
                                                    }} />
                                                </div>
                                                <Label className='wow fadeInUp' data-wow-delay="0.4s">Your Mobile*</Label>
                                                <div className="col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                                                    <AvField name="mobile" placeholder="Your Mobile..." type="number" className="form-control" validate={{
                                                        required: { value: true, errorMessage: "This field is required!" },
                                                    }} />
                                                </div>
                                                <Label className='wow fadeInUp' data-wow-delay="0.6s">Your Message*</Label>
                                                <div className="col-12 wow fadeInUp" data-wow-delay="0.7s">
                                                    <AvField name="desc" className="form-control" placeholder="Your Message..." type="textarea" validate={{
                                                        required: { value: true, errorMessage: "This field is required!" },
                                                    }} />
                                                </div>
                                                <div className="col-12 wow fadeInUp" data-wow-delay="0.8s">
                                                    <button type="submit" name="submit" className="inptBtn hoverButton" >SUBMIT<span>SUBMIT</span></button>
                                                </div>
                                            </div>
                                        </AvForm>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
