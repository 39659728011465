import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { get } from '../pages/helper/helper_api';

export default function FeaturedProject() {

    const [client, setClient] = useState([]);
    const [offer, setOffer] = useState([]);
    const [error, setError] = useState(false);


    useEffect(() => {
        getData();
        getOfferData();
    }, []);

    const getData = () => {
        get("/client/list")
            .then(json => {
                console.log('response from get project list', json);
                if (json?.statusCode == 200) {
                    setClient(json?.data);
                } else {
                    setError(true);
                }
            })
            .catch(error => {
                console.log('error while getting project list', error);
            })
    }

    const getOfferData = () => {
        get("/offer/list")
            .then(json => {
                console.log('response from get project list', json);
                if (json?.statusCode == 200) {
                    setOffer(json?.data);
                } else {
                    setError(true);
                }
            })
            .catch(error => {
                console.log('error while getting project list', error);
            })
    }
    console.log("get client location", client);


    return (
        <section className='bottom padding-left-right featured-section'>
            <div className='header-title mb-40px'>
                <div>
                    {/* <h6 className='sub-heading wow fadeInUp'>AWESOME PORTFOLIO</h6> */}
                    {/* <h2 className='heading wow fadeInUp'>Our World of Building Excellence</h2>
                    <p style={{ textAlign: 'center' }}>Meet Our Team, Explore Our Projects, and Discover Our Products</p> */}
                    {/* <center><div className="blue-line"></div></center> */}
                </div>
                {/* <Link to='/gallery' className='wow fadeInUp button_effect'><div className='line_border'></div>Explore All Works <i class="fa-solid fa-arrow-right"></i></Link> */}
            </div>

            <div class="marquee left">
                <h2 className='heading wow fadeInUp'>Client Location</h2>
                <div class="marquee-content">
                    {client.map((item, index) => (
                        <div class="marquee-item">
                            <li class="left1">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={item?.image} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>{item?.title} </span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>{item?.product}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>{item?.tender} </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>{item?.city}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </li>
                        </div>
                    ))}

                    {client.map((item, index) => (
                        <div class="marquee-item">
                            <li class="left1">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={item?.image} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>{item?.title} </span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>{item?.product}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>{item?.tender} </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>{item?.city}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </li>
                        </div>
                    ))}

                    {client.map((item, index) => (
                        <div class="marquee-item">
                            <li class="left1">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={item?.image} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>{item?.title} </span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>{item?.product}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>{item?.tender} </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>{item?.city}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </li>
                        </div>
                    ))}

                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-1.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash </span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-2.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                </div>
            </div>
            <h2 className='heading wow fadeInUp'>Special Offer</h2>
            <div class="marquee right">
                <div class="marquee-content">

                    {offer.map((item, index) => (
                        <div class="marquee-item">
                            <li class="left1">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={item?.image} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>{item?.title} </span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>{item?.product}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>{item?.tender} </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>{item?.city}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </li>
                        </div>
                    ))}
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-1.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash </span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>

                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-2.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-1.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                </div>
            </div>
        </section>
    )
}
