import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import { post } from './helper/helper_api';
import { toast } from 'react-toastify';
import distributeship from '../assets/images/distributer.pdf';

export default function Contact() {

    const [file, setFile] = useState(null);
    const form = useRef();
    const pdfUrl = distributeship

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const style = {
        color: 'white'
    }

    const handleImgChange = (file) => {
        setFile(file);
    }

    const handleValidSubmit = (e, v) => {
        const body = {
            ...v,
        };
        console.log("check body", body);
        // return;

        post('/distributer', body)
            .then((res => {
                if (res?.statusCode == 200) {
                    toast.success(res?.message);
                    form.current.reset();
                } else {
                    toast.error(res?.error);
                }
            }))
            .catch(error => {
                console.log('error while login', error);
                toast.error('Something went wrong');
            })
    }

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'Distributorship_Form.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <section className='inner-banner'>
                <h1 className='heading' style={style}>DISTRIBUTORSHIP APPLICATION FORM</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true" style={style}></i></li>
                        <li className="breadcrumb-item active" aria-current="page" style={style}>Distributorship Form</li>
                    </ol>
                </nav>
            </section>
            <section className='bottom top padding-left-right '>
                <div className="contact-sec">
                    <div className="container">
                        <center><h2>AMBIKA MAXX INDIA PVT. LTD. </h2>
                            <h3 className="sub-heading wow fadeInUp">Office No. ,3rd Floor, Saran Chamber 1, Park Road, Hazratgunj, Lucknow 226001</h3>
                        </center>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className='footer-fomr-section'>
                                    <div className='contact-form-footer contact-form' style={{ backgroundColor: 'white' }}>
                                        <AvForm ref={form} onValidSubmit={handleValidSubmit}>
                                            <div className='row'>
                                                <h2 style={{ color: '#CD0001' }}>DISTRIBUTORSHIP APPLICATION FORM</h2>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="name"
                                                        className="form-control"
                                                        placeholder="Enter full name."
                                                        label="Name of the Applicant"
                                                        required
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>

                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="mobile"
                                                        className="form-control"
                                                        label="Mobile Name"
                                                        placeholder="Enter mobile."
                                                        type="number"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fax_number"
                                                        className="form-control"
                                                        label="Fax No"
                                                        placeholder="Enter Fax No"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="email"
                                                        className="form-control"
                                                        label="Email"
                                                        placeholder="Enter email."
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="shopname"
                                                        className="form-control"
                                                        placeholder="Enter Firm/Shop name."
                                                        label="Name of the Firm/Shop"
                                                        required
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-12'>
                                                    <AvField
                                                        name="address"
                                                        className="form-control"
                                                        label="Address of the Firm/Shop"
                                                        type="textarea"
                                                    >
                                                    </AvField>
                                                </div>

                                                <h2 style={{ color: '#CD0001' }}>Details of Bank A/c</h2>

                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="bank"
                                                        type="text"
                                                        className="form-control"
                                                        label="Name and address of Bank"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="type_ac"
                                                        className="form-control"
                                                        label="Type of A/c"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="account"
                                                        className="form-control"
                                                        label="Account No"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="signatory"
                                                        className="form-control"
                                                        label="Name of authorised signatory"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>

                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="firm"
                                                        label="Status of firm"
                                                        type="select"
                                                    >
                                                        <option> Select Banner Type</option>
                                                        <option value={"Proprietorship"}>Proprietorship</option>
                                                        <option value={"Partnership"}> Partnership</option>
                                                        <option value={"Limited Company"}> Limited Company</option>
                                                        <option value={"Private Ltd. Co"}> Private Ltd. Co</option>
                                                    </AvField>
                                                </div>
                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="address_associate"
                                                        className="form-control"
                                                        label="Name and address of associate firm(s):"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="turnover"
                                                        className="form-control"
                                                        label="Turnover"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <h2 style={{ color: '#CD0001' }}>Details Security Deposit</h2>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="security"
                                                        className="form-control"
                                                        label="Details of Security Deposit"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="cheque"
                                                        className="form-control"
                                                        label="DD/Cheque No"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="date"
                                                        type="date"
                                                        className="form-control"
                                                        label="Date"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="amount"
                                                        className="form-control"
                                                        label="Amount"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="payable"
                                                        className="form-control"
                                                        label="Payable at"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>

                                            </div>

                                            <div className="offset-md-3 col-md-6 wow fadeInUp" data-wow-delay="0.8s">
                                                <button type="submit" onClick={handleDownload} name="submit" className="inptBtn hoverButton" >SUBMIT<span>SUBMIT</span></button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
