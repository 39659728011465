import React, { useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion"
import { Parallax, useParallax } from 'react-scroll-parallax';
export default function ServiceDetails() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>Service Details</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className='star'><i class="fa-solid fa-star-of-life"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Services</li>
                    </ol>
                </nav>
            </section>

        </>
    )
}
