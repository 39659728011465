import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify';
import { post } from './helper/helper_api';
import { Link } from 'react-router-dom';

export default function Career() {

    const [file, setFile] = useState(null);
    const [formData, setFormData] = useState({});
    const form = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const style = {
        color: 'white'
    }

    const handleValidSubmit = (e, v) => {
        const body = {
            ...v,
        };
        console.log("check body", body);
        // return;

        post('/career', body)
            .then((res => {
                if (res?.statusCode == 200) {
                    toast.success(res?.message);
                    form.current.reset();
                } else {
                    toast.error(res?.error);
                }
            }))
            .catch(error => {
                console.log('error while login', error);
                toast.error('Something went wrong');
            })
    }

    return (
        <>
            <section className='inner-banner'>
                <h1 className='heading' style={style}>Career</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true" style={style}></i></li>
                        <li className="breadcrumb-item active" aria-current="page" style={style}>Career</li>
                    </ol>
                </nav>
            </section>
            <section className='bottom top padding-left-right '>
                <div className="contact-sec">
                    <div className="container">
                        <center><h2>AMBIKA MAXX INDIA PVT. LTD. </h2>
                            {/* <h3 className="sub-heading wow fadeInUp">Office No. 3rd Floor, Saran Chamber 1, Park Road, Hazratgunj, Lucknow 226001</h3> */}
                        </center>
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8">
                                <div className='footer-fomr-section'>
                                    <div className='contact-form-footer contact-form' style={{ backgroundColor: 'white' }}>
                                        <AvForm ref={form} onValidSubmit={handleValidSubmit}>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="name"
                                                        className="form-control"
                                                        placeholder="Enter name."
                                                        label="Your Name*"
                                                        required
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email."
                                                        label="Your Email*"
                                                        required
                                                        type="email"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="mobile"
                                                        className="form-control"
                                                        label="Your Mobile*"
                                                        placeholder="Enter mobile."
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="position"
                                                        label="Position*"
                                                        type="select"
                                                    >
                                                        <option> Select Position</option>
                                                        <option value={"Sales"}> Sales</option>
                                                        <option value={"Marketing"}> Marketing</option>
                                                        <option value={"Other"}> Other</option>
                                                    </AvField>
                                                </div>
                                                <div className='col-md-12'>
                                                    <AvField
                                                        name="desc"
                                                        className="form-control"
                                                        label="Description"
                                                        type="textarea"
                                                    >
                                                    </AvField>
                                                </div>
                                            </div>
                                            <div className="offset-md-3 col-md-6 wow fadeInUp" data-wow-delay="0.8s">
                                                <button type="submit" name="submit" className="inptBtn hoverButton" >SUBMIT<span>SUBMIT</span></button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
