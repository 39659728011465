import React, { useEffect, useRef, useState } from 'react'
import { Parallax, useParallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import CountPage from '../component/CountPage';
import { get } from './helper/helper_api';
export default function About() {

    const swiperRef1 = useRef();
    const [team, setTeam] = useState([]);
    const [error, setError] = useState(false);

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    }, []);

    const style = {
        color: 'white',
    }

    const getData = () => {
        get("/team/list")
            .then(json => {
                console.log('response from get project list', json);
                if (json?.statusCode == 200) {
                    setTeam(json?.data);
                } else {
                    setError(true);
                }
            })
            .catch(error => {
                console.log('error while getting project list', error);
            })
    }

    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading' style={style}>About Us</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true" style={style}></i></li>
                        <li className="breadcrumb-item active" aria-current="page" style={style}>About us</li>
                    </ol>
                </nav>
            </section>

            <section className='top privacy_po padding-left-right about-section-1 '>
                <div className='container-fluid'>
                    <div className='row'>
                        <h3 style={{ color: '#00207b' }}><strong>Building Foundations for Tomorrow</strong></h3>
                        <p>
                            Ambika Maxx India Pvt Ltd is dedicated to manufacturing the highest quality building materials that builders,
                            contractors, and DIY enthusiasts trust. From our state-of-the-art facility in Rajasthan, we produce a variety of essential
                            products including wall putty, decorative white cement, and specialized adhesives.
                            Our commitment to excellence has positioned us as a leader in the building materials industry.
                        </p>
                        <p>As a company, we strive not just to meet but exceed industry standards with every product we offer.
                            Our focus on sustainability guides our practices and product development, ensuring we contribute positively to
                            our environment while delivering unparalleled quality to our customers.
                            Join us in building a stronger, more beautiful future.
                        </p>
                    </div><br />
                    <button type="button" class="btn btn-danger">Contact Us &nbsp;<i class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
                </div>
            </section>

            <section className='top bottom padding-left-right featured-section'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='award-img-left'>
                                <img src={require('../assets/images/about-right.jpg')} height='40%' width='100%' alt="service" />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='award-right-text'>
                                {/* <h6>Why choose us</h6> */}
                                <h2 className='heading wow fadeInUp' style={{ color: '#00207b' }}>Vision</h2>
                                <p className='paragraph1 wow fadeInUp'>At Ambika Maxx India Pvt Ltd, our vision is to redefine the standard of building materials globally. We aim to lead with innovative products that are recognized for their superior quality and environmental sustainability. By consistently pushing the boundaries of technology and service, we strive to be the trusted choice for builders and homeowners worldwide, contributing to more robust and aesthetically pleasing constructions.</p>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className='award-right-text top'>
                                {/* <h6>Why choose us</h6> */}
                                <h2 className='heading wow fadeInUp' style={{ color: '#00207b' }}>Mission</h2>
                                <p className='paragraph1 wow fadeInUp'>Our mission is to deliver unparalleled building solutions that enhance both the infrastructure and the lives of those who use them. Through a relentless focus on quality, customer satisfaction, and eco-friendly practices, we commit to providing our customers with reliable products that support safe and beautiful building environments. We aim to empower our partners and customers with the knowledge and materials to construct durable and sustainable projects.</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='award-img-left'>
                                <img src={require('../assets/images/why-choose.jpg')} height='40%' width='100%' alt="service" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CountPage />

        </>
    )
}
