import React, { useEffect, useRef } from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useParallax } from 'react-scroll-parallax';
import ServiceInclude from './ServiceInclude';
import { Link } from 'react-router-dom';
import Whychooseus from '../../component/Whychooseus';
import Information from '../../component/Information';
export default function OutofHome() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const style = {
        color: 'white'
    }
    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading' style={style}>Gypsum Plaster (POP)</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true" style={style}></i></li>
                        <li className="breadcrumb-item active" aria-current="page" style={style}>Gypsum Plaster (POP)</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='service-detail-left-side'>
                                <h3 className='sub-heading'> OUR SERVICES</h3>
                                <h2 className='heading'>Gypsum Plaster (POP)</h2>
                                <p>Our Gypsum Plaster (POP) is crafted for excellence, providing a superior smooth finish that enhances the aesthetic of any interior space. Whether you’re covering large areas or adding fine decorative touches, our plaster is available in 2 kg, 20 kg, and 25 kg bags, ensuring you have the right quantity for both small fixes and extensive renovations.</p>
                                <p>Easy to mix and apply, our Gypsum Plaster sets quickly and minimizes the risk of cracking, making it a reliable choice for achieving a durable, smooth surface. Its high-quality formulation allows for a fine finish that can be painted or decorated in any style, transforming your walls into a perfect canvas for your artistic expressions.</p>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/out-of-home/gypsum-1.jpeg')} height="200px" />
                                            <h4> 2 Kg</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/out-of-home/gypsum-2.jpeg')} height="200px" />
                                            <h4> 20 Kg</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/out-of-home/gypsum-3.jpeg')} height="200px" />
                                            <h4> 25 Kg</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <ServiceInclude />
                        </div>
                    </div>
                </div>
            </section>
            <Information />
        </>
    )
}
