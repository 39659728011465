import React, { useEffect, useState } from 'react'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { Link } from 'react-router-dom'
import Information from '../../component/Information'

export default function GalleryPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const style = {
        color: 'white'
    }
    return (
        <>
            <section className='inner-banner'>
                <h1 className='heading' style={style}>Gallery</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true" style={style}></i></li>
                        <li className="breadcrumb-item active" aria-current="page" style={style}>Gallery</li>
                    </ol>
                </nav>
            </section>
            <section className='padding-left-right top bottom'>
                <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
                >
                    <Masonry>
                        <div className='card_box_gallery'>
                            <Link to='/gallery/board-meeting'>
                                <div className='gallery-thumb'>
                                    <img src={require('../../assets/images/gallery/product/5.jpg')} />
                                </div>
                                <h4 className='sub-heading'>Our Products</h4>
                            </Link>
                        </div>

                        <div className='card_box_gallery'>
                            <Link to='/gallery/happy-client'>
                                <div className='gallery-thumb'>
                                    <img src={require('../../assets/images/gallery/happy-client/3.jpg')} />
                                </div>
                                <h4 className='sub-heading'>Happy Clients</h4>
                            </Link>
                        </div>

                        <div className='card_box_gallery'>
                            <Link to='/gallery/diwali'>
                                <div className='gallery-thumb'>
                                    <img src={require('../../assets/images/gallery/works/3.jpg')} />
                                </div>
                                <h4 className='sub-heading'>Our Works</h4>
                            </Link>
                        </div>

                        <div className='card_box_gallery'>
                            <Link to='/gallery/employees-training'>
                                <div className='gallery-thumb'>
                                    <img src={require('../../assets/images/gallery/celebration/1.jpg')} />
                                </div>
                                <h4 className='sub-heading'>Celebration</h4>
                            </Link>
                        </div>

                        {/* <div className='card_box_gallery'>
                            <Link to='/gallery/new-year-celebration'>
                                <div className='gallery-thumb'>
                                    <img src={require('../../assets/images/gallery/New-Year-Celebration/1.jpeg')} />
                                </div>
                                <h4 className='sub-heading'>New Year Celebration</h4>
                            </Link>
                        </div>

                        <div className='card_box_gallery'>
                            <Link to='/gallery/shimla-out-bond'>
                                <div className='gallery-thumb'>
                                    <img src={require('../../assets/images/gallery/Shimla-Out-Bond/1.jpg')} />
                                </div>
                                <h4 className='sub-heading'>Shimla Out Bond</h4>
                            </Link>
                        </div> */}

                    </Masonry>
                </ResponsiveMasonry>
            </section>
            <Information />
        </>
    )
}
